export const environment = {
  apiUrl: 'https://hoxton.cloud/api/v0',
  auth0Audience: 'https://api.hoxton.cloud',
  auth0ClientId: 'XWIIbCnpjVJ2tdjJJP0eJxL6mJLd5q4W',
  auth0Domain: 'auth.hoxton.ai',
  gtagId: 'G-6SQL2GZCSW',
  reportingUrl: 'https://reporting.hoxton.ai',
  cookieDomain: 'app.hoxton.ai',
  production: true,
  gcpProject: 'just-lemming-24307',
  envName: 'prod',
  hubspot: {
    visitorIdentificationUrl: 'https://hsproxy.ew.r.appspot.com/conversations/v3/visitor-identification/tokens/create',
  },
};
